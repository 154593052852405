@use '../base/var' as *;

.searchable-dropdown {
  position: relative;

  input {
    padding-right: 23px;
  }

  &__options {
    border: 1px solid gray;
    background-color: white;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 9;
    max-height: 500px;
    overflow-y: scroll;

    li {
      padding: .5rem 1rem;
      cursor: pointer;

      &:hover, &[aria-selected=true] {
        background-color: $primary;
        color: white;
      }
    }
  }

  &__reset-button {
    position: absolute;
    right: 0;
    top: 0;

    button {
      height: auto;
      padding: 4px 10px;
      margin: 0;
      border: 2px solid #c8c8c8;

      svg {
        width: 10px;
        height: 10px;
        fill: $gray;
      }
    }
  }
}