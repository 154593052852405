@use '../../base/var' as *;

.page-appliance-shop-orders {
  .wrapper {
    background-color: $gray-light;
    min-height: calc(100vh - 117px);
  }

  .current-orders-table {
    margin: 0 auto;
    border-collapse: separate;
    border-spacing: 0 1rem;

    td, th {
      text-align: left;
    }

    thead {
      th {
        padding: 0 30px;
        font-weight: bold;
        color: $secondary;
      }
    }

    tbody {
      tr {
        background-color: #fff;
        padding: 20px 30px;
        margin-bottom: 1rem;

        &:not(.order-details) {
          cursor: pointer;
        }

        &.order-details {
          transform: translateY(-1rem);

          td {
            padding: 0;
          }

          .order-details-wrapper {
            display: flex;
            justify-content: space-between;
            padding: 30px 120px;


            h2 {
              margin-bottom: 2rem;
            }
          }
        }

        td {
          padding: 20px 30px;

          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }

          .fa-chevron-right {
            margin-left: 20px;

            &.expanded {
              transition: all .2s ease;
              rotate: 90deg;
            }
          }
        }
      }
    }

    .order-status-label {
      white-space: nowrap;
      color: white;
      padding: 5px 10px;
      border-radius: 3px;
      font-weight: bold;

      &--received {
        background-color: $order-received;
      }

      &--processing {
        background-color: $order-processing;
      }

      &--packaged {
        background-color: $order-packaged;
      }

      &--delivering {
        background-color: $order-delivering;
      }
    }

    .order-status {
      color: $secondary;

      &__icons {
        gap: 1rem;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        position: relative;
        margin-bottom: 40px;

        &:before {
          content: '';
          display: block;
          width: 100%;
          height: 5px;
          background-color: $secondary;
          position: absolute;
          bottom: -23px;
        }

        .order-status-icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;

          &.current-status:after {
            display: block;
          }

          &:after {
            content: '';
            display: none;
            position: absolute;
            background-color: $secondary;
            width: 20px;
            height: 20px;
            outline: 3px solid white;
            border-radius: 20px;
            bottom: -30px;
          }

          i {
            font-size: 2rem;
          }
        }
      }
    }
  }

  .completed-orders {
    form {
      display: flex;
      align-items: self-end;
      gap: 1rem;
      margin-bottom: 2rem;

      .search-fields {
        flex: 1;
        display: flex;
        gap: 1rem;

        .form-control {
          margin-bottom: 0;
          flex: 1;
        }
      }

      button {
        margin: 0;
      }
    }
  }
}