@use '../base/var' as *;

.flash {
  width: max-content;
  position: fixed;
  right: 12px;
  top: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: .5rem;

  &__message {
    display: flex;
    width: 350px;
    font-size: .875rem;
    background-color: rgba(255, 255, 255, .85);
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
    border-radius: .25rem;
    padding: .75rem .75rem .75rem 2.5rem;
    word-wrap: break-word;
    background-color: white;
    position: relative;
    transition: all ease 500ms;
    position: relative;

    button {
      margin: 0;
      position: absolute;
      right: 0;
      top: 0;
      padding: .75rem 0.5rem;

      &:hover {
        svg {
          fill: black;
        }
      }

      svg {
        fill: $gray;
        width: 12px;
        height: 12px;
      }
    }

    &:before {
      content: '';
      height: 20px;
      width: 20px;
      display: block;
      position: absolute;
      left: .75rem;
      -webkit-mask-position: center;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-image: var(--image-url);
      mask-image: var(--image-url);
    }

    &--permanent {
      padding: .75rem 3.4rem .75rem 2.5rem;
    }

    &--leave {
      transform: translateY(-100%);

      &-start {
        opacity: 1;
      }

      &-end {
        opacity: 0;
      }
    }

    &--success {
      background-color: #e5f3f0;

      &:before {
        background-color: $alert-success;
        --image-url: url('~../../_img/icons/square-check.svg');
      }
    }

    &--info {
      background-color: #e5f3f0;

      &:before {
        background-color: $alert-info;
        --image-url: url('~../../_img/icons/circle-info.svg');
      }
    }

    &--warning {
      background-color: #fff0e0;

      &:before {
        background-color: $alert-warning;
        --image-url: url('~../../_img/icons/triangle-exclamation.svg');
      }
    }

    &--error {
      background-color: #fdeded;

      &:before {
        background-color: $alert-error;
        --image-url: url('~../../_img/icons/square-xmark.svg');
      }
    }
  }
}
