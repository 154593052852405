@use '../base/var' as *;

.appliance-shop-products {
  .search-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    &__search-field {
      position: relative;
      width: 100%;

      button {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }

      input {
        padding-right: 35px;
      }
    }

    input, button {
      margin: 0;
      height: auto;
    }
  }

  &__amount-found {
    color: $secondary;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__products {
    display: grid;
    gap: 1rem;

    @media only screen and (min-width: $breakpoint-sm) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media only screen and (min-width: $breakpoint-lg) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media only screen and (min-width: $breakpoint-xl) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .product-wrapper {
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.16);
      transition: all 200ms ease;

      &:hover {
        transform: translateY(-3px);
        box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.25);
      }

      .product {
        padding: 1rem;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        &__image {
          height: 250px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          svg {
            position: absolute;
            bottom: 10px;
            right: 10px;
            width: 20px;
            height: 20px;
            outline: 2px solid white;
            border-radius: 50%;
          }
        }

        &__title {
          font-size: 1rem;
        }

        &__description {
          font-size: 1rem;
          color: $gray;
          font-weight: normal;
          word-break: break-word;
        }

        &__price {
          margin-top: auto;
          color: $secondary;
          font-weight: bold;

          > span {
            font-size: 1.25rem;
          }

          button {
            width: 100%;
            margin: 0;
          }
        }
      }
    }
  }

  &__pagination {
    display: flex;
    gap: .5rem;
    justify-content: center;
    margin-top: 1rem;

    li {
      button {
        padding: .75rem;
        border-radius: 5px;
        border: 2px solid transparent;

        &:hover {
          border-color: $primary;
          background-color: $primary;
          color: white;
        }

        &.active {
          border-color: $primary;
          background-color: $primary;
          font-weight: bold;
          color: white;
        }
      }
    }
  }
}