@use '../base/var' as *;

.button {
  padding: 8px 15px;
  white-space: nowrap;
  overflow: initial;
  display: inline-block;
  height: auto;
  transition: all 200ms ease;
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }

  &--link {
    height: auto;
    background: none !important;
    border: none;
    padding: 0 !important;
    color: #069;
    text-align: left;
  }

  &--transparent {
    padding: 0;
    margin: 0;
    background-color: transparent;
    margin: 0;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }

  &--primary {
    background-color: $primary;
    color: white;

    &:hover, &[disabled], &.button--disabled {
      background-color: $primary-dark;
      color: white;
    }
  }

  &--tertiary {
    background-color: $secondary;
    color: white;

    &:hover, &[disabled], &.button--disabled {
      background-color: $secondary-dark;
      color: white;
    }
  }

  &--outlined {
    outline: 2px solid $primary;
    background-color: transparent !important;
    color: $primary !important;

    &:hover, &[disabled], &.button--disabled {
      background-color: $primary !important;
      color: white !important;
    }
  }

  &--accept {
    color: white;
    background-color: $alert-success;

    &:hover {
      color: white;
      background-color: $alert-success-hover;
    }
  }

  &--cancel {
    color: white;
    background-color: $alert-error;

    &:hover {
      color: white;
      background-color: $alert-error-hover;
    }
  }

  &--disabled {
    pointer-events: none;
  }
}