@use '../base/var' as *;

.form-control {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: .5rem;
  margin-bottom: 1rem;

  //@media only screen and (min-width: $breakpoint-md) {
  //  gap: 1rem;
  //  margin-bottom: 2rem;
  //}


  &__alert {
    &--error {
      color: $alert-error;
    }
  }

  &__alert {
    &--error {
      color: $alert-error;
    }
  }

  &__alert {
    &--error {
      color: $alert-error;
    }
  }

  &__alert {
    &--error {
      color: $alert-error;
    }
  }

  &--100 {
    grid-template-columns: 100px 1fr;
  }

  &--200 {
    grid-template-columns: 200px 1fr;
  }

  &--300 {
    grid-template-columns: 300px 1fr;
  }

  &--400 {
    grid-template-columns: 400px 1fr;
  }

  input:not([type=checkbox]), select, textarea {
    width: 100%;
    margin-bottom: 0 !important;
  }

  &--required {
    label::after {
      content: ' *';
      font-size: .75rem;
      color: red;
    }
  }

  &__description {
    color: $gray;
    font-size: 80%;
  }

  &--stacked {
    grid-template-columns: 1fr;
  }

  &--vertical-center{
    align-items: center;
  }
}