@use '../base/var' as *;

.table {
  background-color: white;

  thead {
    th {
      font-weight: bold;
      vertical-align: top;

      &:hover {
        background-color: $primary;
        color: white;
      }

      &:after {
        background: url('~../../_img/icons/arrow.svg') no-repeat center;
        background-size: auto;
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        visibility: hidden;
      }

      &.sorted {
        &--asc:after {
          transform: rotate(180deg);
        }

        &:after {
          visibility: visible;
        }
      }
    }
  }

  td, th {
    padding: .3rem .5rem;
    text-align: left;
    border: 1px solid #f1f2f3;
  }

  &--striped {
    > tbody {
      tr {
        &:nth-of-type(even) {
          background: #f9f9f9;
        }
      }
    }
  }

  &--full-width {
    width: 100%;
  }

  &--primary {
    thead {
      background-color: $primary;
      color: white;

      th, td {
        border-color: transparent;
        padding: 10px 15px;
      }
    }

    td {
      padding: 10px 15px;
    }
  }

  &--trim-edges {
    td, th {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }


  .toggle-row-button {
    transition: all 200ms ease;

    &.expanded i {
      transform: rotate(90deg);
    }
  }
}