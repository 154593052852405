@use '../../base' as *;

.page-admin-form-overview {
  background-color: green;

  .container {
    background-color: white;
  }

  .organization-select {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    li {
      .button {
        width: 100%;
        text-align: left;
        background-color: $primary;
        color: white;
        padding: 1rem 2rem;

        &:hover {
          background-color: $primary-dark;
        }
      }
    }
  }

  span {
    color: #989898;
  }

  label {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 32px;
    cursor: pointer;
  }

  label input {
    position: relative;
    z-index: 1;
    appearance: none;
    margin-bottom: 1rem;
  }

  label span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #37becc;
    border-radius: 80px;
    transition: 0.5s;
  }

  label input:checked~ span {
    background-color: #2c868e;
  }

  label span i {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 50%;
    transition: 0.5s;
  }

  label input:checked~ span i {
    left: 50px;
  }

  label span i ::before {
    content: '';
  }

}