@use '../base/var' as *;

.multi-select {
  position: relative;

  [x-ref=selectField] {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
  }

  &__selection {
    background-color: white;
    border: 2px solid #c8c8c8;
    padding: 3px;
    border-radius: 3px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    cursor: text;

    &:hover, &.focused {
      border-color: $primary;
    }

    &.focused {
      border-radius: 3px 3px 0 0;
    }

    .multi-select-list {
      display: flex;
      flex-wrap: wrap;
      gap: .3rem;

      li {
        background-color: #e4e4e4;
        border: 1px solid #aaa;
        border-radius: 4px;
        box-sizing: border-box;
        display: inline-block;
        padding: 2px 6px;
        position: relative;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: bottom;
        white-space: nowrap;
        cursor: default;

        button {
          padding: 0;
          margin: 0;
          height: auto;

          &:hover {
            color: black;
          }
        }
      }
    }


    textarea {
      background: transparent;
      outline: 0;
      box-shadow: none;
      box-sizing: border-box;
      border: none;
      font-size: 15px;
      margin-top: 5px;
      margin-left: 5px;
      margin-bottom: 0;
      padding: 0;
      max-width: 100%;
      resize: none;
      height: 18px;
      vertical-align: bottom;
      font-family: sans-serif;
      overflow: hidden;
      word-break: keep-all;
      min-height: unset;
      border-radius: 0;

      &:focus {
        outline: none !important;
      }
    }
  }

  &__dropdown {
    width: 100%;
    border: 2px solid #c8c8c8;
    max-height: 200px;
    overflow: auto;
    position: absolute;
    bottom: 2px;
    transform: translateY(100%);
    resize: vertical;
    border-top-color: transparent;
    border-radius: 0 0 3px 3px;

    &[style*="height"] {
      max-height: unset;
    }

    &--show-above {
      border-top-color: #c8c8c8;
      border-bottom-color: transparent;
      top: 0;
      transform: translateY(-100%);
      bottom: auto;
    }

    button {
      border-radius: 0;
      text-align: left;
      width: 100%;
      height: auto;
      padding: 8px;
      margin: 0;
      background-color: white;
      transition: all 100ms ease;

      &.selected {
        background-color: hsl(208 7% 88% / 1);
      }

      &:hover {
        background-color: $primary;
        color: white;
      }
    }
  }
}