@use '../../base/var' as *;

.page-appliance-shop-quote {
  main {
    display: flex;
    justify-content: center;
    container-type: inline-size;
  }

  .quote {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    margin: 0 auto;
    padding-bottom: 3rem;

    @container (min-width: 1140px) {
      grid-template-columns: minmax(auto, 800px) 450px;
      gap: 2rem;
    }

    &__preview {
      padding: 4rem;
      border-radius: 10px;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, .1);

      .quote-preview {

        .editable-quote-fields {
          position: relative;

          .edit-orderlines {
            color: white;
            background-color: $primary;
            position: absolute;
            left: -9px;
            top: 0;
            transform: translateX(-100%);
            padding: 1px 4px 2px 6px;
            border-radius: 3px 0 0 3px;

            &:hover {
              background-color: $primary-dark;
            }
          }

          &:after {
            content: '';
            border: 2px none $primary;
            border-left-style: solid;
            position: absolute;
            height: 100%;
            width: 1px;
            left: -10px;
            top: 0;
          }
        }

        table {
          th {
            font-weight: bold;
          }

          td, th {
            text-align: left;
            vertical-align: top;
          }
        }

        &__orderlines {
          padding: 1rem 0;

          td, th {
            padding: 10px 5px;
            //white-space: nowrap;
          }

        }

        &__totals {
          td, th {
            padding: 10px 5px;
          }
        }
      }
    }

    &__info {
      .quote-metadata {
        background-color: $gray-medium;
        color: $secondary;
        padding: 35px;
        border-radius: 3px;
        margin-bottom: 1rem;

        table {
          td {
            padding: 0 5px;
          }
        }
      }

      .quote-actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;

        > * {
          flex: 1;
          margin: 0;
        }
      }
    }
  }

  .quote-confirmation {
    display: grid;
    grid-template-columns: 30% 1fr;
    gap: 3rem;
    height: 100vh;

    &__image {

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    &__text {
      padding-top: 20%;
    }
  }

  .edit-orderlines-modal {
    width: 650px;

    &__orderlines {
      table {
        width: 100%;

        th {
          font-weight: bold;
        }

        td, th {
          text-align: left;
          vertical-align: middle;
          padding: 5px 10px;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }

        input {
          max-width: 50px;
          margin: 0;
        }
      }
    }

    &__totals {
      display: flex;
      justify-content: flex-end;

      td {
        padding: 2px 10px;
      }
    }
  }
}