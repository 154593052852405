@use '../base/var' as *;

.appliance-picker {
  height: 100%;
  width: 100%;
  padding: 1rem;

  &__appliances-in-depot {
    height: 100%;
    display: flex;
    flex-direction: column;

    .appliance-display {
      height: 100%;
      display: grid;
      grid-template-columns: 200px 1fr;
      min-height: 0;

      &__appliance-group-list {
        overflow-x: hidden;
        height: 100%;
        min-width: 200px;
        margin-right: 2rem;
        padding-right: 10px;
        padding-bottom: 20px;

        &:hover {
          padding-right: 0;
          overflow-y: scroll;
        }

        button {
          &:hover {
            text-decoration: underline;
          }

          &.active {
            text-decoration: underline;
            font-weight: bold;
          }
        }
      }

      &__appliances-display {
        padding: 0 1rem;
        width: 100%;
        overflow-y: scroll;

        .filters {
          margin-top: .5rem;
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;

          > div {
            border: 1px solid $blue;
            position: relative;
            cursor: pointer;

            &.active {
              background-color: $blue;
              color: white;
            }

            > span {
              padding: .5rem 1rem;
              display: inline-block;

              &:hover {
                background-color: $blue;
                color: white;
              }
            }

            ul {
              position: absolute;
              background: white;
              bottom: -1px;
              left: 0;
              transform: translateY(100%);
              background-color: white;
              outline: 1px solid black;
              z-index: 9999;

              li {
                padding: .5rem 1rem;
                cursor: pointer;
                color: $blue;

                &:hover {
                  background-color: $blue;
                  color: white;
                }

                &:focus {
                  outline: red;
                }
              }
            }

            .removeFilter {
              padding: 0;
              margin: -3px 10px 0px 0;
              height: auto;

              svg {
                width: 10px;
                height: 10px;
                fill: white;
              }
            }
          }
        }

        input {
          width: 100%;
          margin-bottom: 0;
        }

        #images-notification {
          color: $danger;
          margin-bottom: 1rem;
        }

        .appliances {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 1rem;

          .appliance {
            background-color: #F7F7F7;
            padding: 10px;

            a:hover {
              text-decoration: underline;
            }

            &__image {
              width: 200px;
              aspect-ratio: 1/1;

              .appliance-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            &__properties {
              td {
                vertical-align: top;

                &:first-child {
                  padding-right: 10px;
                }
              }
            }
          }
        }

      }

      &__info {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__results {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);

    .appliance {
      background-color: #f7f7f7;
      padding: 10px;

      &__image {
        aspect-ratio: 1/1;
        width: 200px;

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      table {
        td {
          vertical-align: top;
        }
      }
    }
  }
}