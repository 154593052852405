@use 'base' as *;
@use 'components';
@use 'views';
@use 'layouts';
@import '../../../node_modules/pikaday/css/pikaday.css';


body {
  font-family: $font-family-sans-serif;
  overflow: auto;
}

.page-header {
  text-align: left;
  padding: 20px 20px 0 20px;
  height: auto;
  border-bottom: 1px solid #f2f2f2;
  background-color: white;
  z-index: 999;
  position: relative;
}

.close-button {
  width: 20px;
  height: 20px;
}

.message-block {
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  > div {
    background-color: white;
    padding: 2rem;
    border-radius: 5px;
    border: 1px solid #f2f2f2;
  }
}

main {
  padding-top: 2rem;

  &.no-padding {
    padding-top: 0;
  }
}

.main-content {
  width: 100%;
  max-height: 100vh;
  overflow: scroll;
}

.main-layout {
  display: flex;
  margin-top: 50px;
}

//TODO some of these styles should go to a separate file.

.mobile-header {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  width: 100vw;
  z-index: 100;
  min-height: 0;
  max-height: 50px;
  transition: max-height 0.4s ease-in-out;
  background: #2dbdcc;
  text-align: center;
}


.mobile-header__logo {
  position: relative;
  top: -27px;
  display: flex;
  justify-content: center;
}

.mobile-header__toggle {
  color: white;
  position: absolute;
  padding: 13px 10px 0 21px;
  display: block;
  height: 37px;
  left: 0;
  z-index: 99;
}

.menu-wrapper {
  width: 100vw;
  z-index: 9999;
}

.hide-scrollbar {
  overflow-y: hidden;

  &:hover {
    overflow-y: scroll;
  }

  &--animate {
  }
}

@media only screen and (min-width: 1024px) {
  .menu-wrapper {
    width: 240px;
    height: 100dvh;
    height: 100vh;

    &.collapsed {
      width: 30px;
    }
  }

  .main-layout {
    flex-direction: row !important;
    margin-top: 0 !important;
  }

  .mobile-header {
    display: none;
  }
}

[x-cloak] {
  display: none !important;
}