@font-face {
  font-family: 'Larsseit';
  font-weight: normal;
  src: url('~../../_style/webfonts/34FB68_0_0.eot');
  src: url('~../../_style/webfonts/34FB68_0_0.eot?#iefix') format('embedded-opentype'),
  url('~../../_style/webfonts/34FB68_0_0.woff2') format('woff2'),
  url('~../../_style/webfonts/34FB68_0_0.woff') format('woff'),
  url('~../../_style/webfonts/34FB68_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Larsseit';
  font-weight: bold;
  src: url('~../../_style/webfonts/34FB68_2_0.eot');
  src: url('~../../_style/webfonts/34FB68_2_0.eot?#iefix') format('embedded-opentype'),
  url('~../../_style/webfonts/34FB68_2_0.woff2') format('woff2'),
  url('~../../_style/webfonts/34FB68_2_0.woff') format('woff'),
  url('~../../_style/webfonts/34FB68_2_0.ttf') format('truetype');
}

$font-family-sans-serif: 'Larsseit', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-serif: Romain BP Text, serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: bold;
  line-height: 1.2;
  color: #011f6e;
}

//h1 {
//  font-family: $font-family-sans-serif;
//  font-weight: 400;
//}

.h1, h1 {
  font-size: 2rem;
  //font-weight: bold;
}

h2, .h2 {
  font-size: 1.2rem;
  font-weight: bold;
}

h3, .h3 {
  font-size: 1rem;
}

//
//@media (max-width: 1200px) {
//  .h1, h1 {
//    font-size: calc(1.2rem + 1.06667vw)
//  }
//}
//
//.h2, h2 {
//  font-size: 1.2rem
//}
//
//@media (max-width: 1200px) {
//  .h2, h2 {
//    font-size: calc(1rem + .53333vw)
//  }
//}
//
//.h3, h3 {
//  font-size: 1.125rem
//}
//
//@media (max-width: 1200px) {
//  .h3, h3 {
//    font-size: calc(1.025rem + .13333vw)
//  }
//}
//
//.h4, h4 {
//  font-size: 1rem
//}
//
//.h5, h5 {
//  font-size: .9375rem
//}
//
//.h6, h6 {
//  font-size: .875rem
//}
//
//.lead {
//  font-weight: 300;
//  font-size: 1.40625rem
//}
//
//@media (max-width: 1200px) {
//  .lead {
//    font-size: calc(1.08125rem + .43333vw)
//  }
//}
//
//p, .p{
//  line-height: 20px;
//}