@use '../../base/var' as *;

.page-appliance-shop-product {

  main {
    padding: 20px;
  }

  .product-body {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 2rem;
    align-items: flex-start;

    &__aside {
      padding: 1rem;
      background-color: $gray-light;
      border: 1px solid hsl(0 0% 88% / 1);
      display: flex;
      flex-direction: column;

      button {
        margin: 0;
      }
    }
  }
}