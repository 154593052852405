.new-pagination {
  display: flex;
  gap: .5rem;
  justify-content: center;
  margin-top: 1rem;

  li {
    .button {
      border: 2px solid transparent;
      border-radius: 5px;
      padding: .75rem;

      &.active {
        font-weight: 700;
      }

      &:hover, &.active {
        background-color: #37becc;
        border-color: #37becc;
        color: #fff;
      }
    }
  }
}