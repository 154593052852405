@use '../../base/var' as *;

.page-appliance-request-confirmation {
  main {
    display: flex;
  }

  .application-confirmation {
    display: grid;
    grid-template-columns: 30% 1fr;
    gap: 3rem;
    height: 100vh;

    &__image {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    &__text {
      padding-top: 20%;
    }
  }
}

