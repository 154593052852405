@use '../base/var' as *;

.file-upload {
  &__images {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: .5rem;
    margin-bottom: 1rem;


    .image {
      background-color: white;
      aspect-ratio: 1/1;
      border-radius: 3px;
      border: 1px solid $gray;
      position: relative;
      padding: .2rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      button {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;

        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  &__control {
    label {
      display: inline-block;

      i {
        font-size: 1.3rem;
      }
    }

    input {
      display: none;
    }
  }
}