@use '../../base/var' as *;

.page-appliance-shop-management {
  .products-filter {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }

  .fa-square-check {
    color: $alert-success;
  }

  .fa-square-xmark {
    color: $alert-error;
  }

  .products-table {
    table-layout: fixed;

    .dark-row {
      background: #f9f9f9;
    }
  }
}