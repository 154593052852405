$blue: #011f6e;
$blue-light: hsl(223, 98%, 32%);
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #d0021b;
$yellow: #fff3c8;
$green: #417505;
$teal: #d6ebe7;
$cyan: #37becc;
$primary: #37becc;
$primary-dark: #198793;
//hsl(186 59% 41% / 1);
$secondary: #011f6e;
$secondary-dark: hsl(223 98% 32% / 1);
$success: #417505;
$info: #37becc;
$warning: #fff3c8;
$danger: #d0021b;
$dark: #343a40;
$orange: #feefe5;
$transparent: transparent;
$white: #fff;
$gray: #6c757d;
$gray-medium: #E6E6E6;
$gray-light: #f9f9f9;
$gray-medium: #E6E6E6;
$light: #f8f9fa;
$gray-dark: #343a40;
$white-40: hsla(0, 0%, 100%, .4);

$order-received: #2D87B0;
$order-processing: #FFA13D;
$order-packaged: #36A18D;
$order-delivering: #9B72AA;

$alert-success: #36A18D;
$alert-success-hover: #2D8776;
$alert-warning: #FFA13D;
$alert-error: #EB5252;
$alert-error-hover: #D14949;
$alert-info: #3178C6;
$alert-info-hover: #275A8E;
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1200px;

$border-radius-sm: 5px;