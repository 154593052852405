@use '../base/var' as *;

.productGallery {

  &__image {
    height: 600px;
    overflow: hidden;
    margin-bottom: 1rem;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;

      &.image--contain {
        object-fit: contain;
      }
    }
  }

  &__previews {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    > button {
      margin: 0;
      padding: 0;
      width: 100px;
      height: 100px;
      border-radius: 5px;
      overflow: hidden;
      border: 1px solid $gray;

      &.active {
        border-color: $primary;
        border-width: 3px;
      }


      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}