@use '../base/var' as *;

.shopping-basket {
  display: inline-flex;
  padding: 0;
  margin: 0;
  font-size: 33px;
  color: $secondary;
  position: relative;

  &.has-products::after {
    display: block;
  }

  &::after {
    background-color: #37becc;
    border-radius: 50px;
    color: #fff;
    content: attr(data-product-amount);
    outline: 2px solid #fff;
    padding: 2px;
    position: absolute;
    right: -4px;
    top: 28px;
    font-size: 1rem;
    line-height: 14px;
    display: none;
    min-width: 18px;
  }
}