@use '../../base/var' as *;

.page-appliance-shop-basket {
  .basket {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;

    &__products {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex: 1;

      .product {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
        padding: 16px;
        gap: 1rem;
        border: 1px solid $gray-light;

        &--not-available {
          border: 1px solid $alert-error;
          border-radius: 3px;
        }


        &__image {
          height: 100px;
          width: 100px;
          //flex: 0 0 100px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }

        &__text {
          flex-grow: 1;
          grid-column: span 4;
        }

        &__end {
          flex: 0 0;
          display: flex;
          align-items: center;
          gap: 1rem;
          justify-content: flex-end;

          .amount {
            width: 63px;
            margin: 0;
          }

          .price {
            white-space: nowrap;
            flex: 1;
            margin: 0;
            width: 50px;
          }

          .remove {
            align-self: center;
            color: $primary;
            font-size: 24px;
            margin-left: 1.3rem;
          }
        }
      }
    }

    &__order-info {
      max-width: 500px;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .prices-table {
        padding: 1rem 2rem;
        background-color: $secondary;
        color: white;

        table {
          width: 100%;

          td {
            padding: 1px 5px;

            &:last-child {
              text-align: right;
            }
          }
        }
      }

      .request-data {
        padding: 1rem 2rem;
        background-color: $gray-medium;
        color: $secondary;

        td {
          padding: 1px 5px;

          &:first-child {
            width: 100%;
          }
        }

        input, select {
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}



