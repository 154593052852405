@use '../../base/var' as *;

.page-mobility-wheelchair-report-data {

  .form-data {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
  }

  .form-group {
    display: flex;
    justify-content: space-between;
  }

  .form-label {
    font-weight: bold;
    flex-basis: 30%;
  }

  .form-value {
    margin-left: 20px;
    color: #555;
    flex-basis: 65%;
    word-wrap: break-word;
  }
}