@use '../../base/var' as *;

.page-mobility-delivery-notes {
  .alpineTableFilter__category form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .alpineTableFilter__category form input,
  .alpineTableFilter__category form select,
  .alpineTableFilter__category form button {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .alpineTableFilter__category form button {
    cursor: pointer;
  }
}