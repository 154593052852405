@use '../base/var' as *;

/**
* DISPLAY
*/
.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

/**
* LAYOUT
*/


.wrapper {
  padding-right: 20px;
  padding-left: 20px;

  &--full-height {
    min-height: 100dvh;
    min-height: 100vh;
  }

  &--gray {
    background-color: $gray-light;
  }

}

.centered {
  margin: 0 auto;
}

@media (min-width: 992px) {
  .legacy-layout .container {
    max-width: 1000px
  }
}

@media (min-width: 1200px) {
  .legacy-layout .container {
    max-width: 1200px
  }
}