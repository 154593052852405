@use '../base/var' as *;


.range-slider {
  position: relative;
  border-radius: 10px;
  text-align: left;
  margin: 45px 0 10px 0;

  &__track {
    position: absolute;
    left: 13px;
    right: 15px;
    height: 14px;
    background-color: #CCC;
    border-radius: 10px;

    //.inverse-left {
    //  position: absolute;
    //  left: 0;
    //  height: 14px;
    //  border-radius: 10px;
    //  background-color: #CCC;
    //  margin: 0 7px;
    //}
    //
    //.inverse-right {
    //  position: absolute;
    //  right: 0;
    //  height: 14px;
    //  border-radius: 10px;
    //  background-color: #CCC;
    //  margin: 0 7px;
    //}

    .range {
      position: absolute;
      left: 0;
      height: 14px;
      border-radius: 14px;
      background-color: #1ABC9C;
    }

    .thumb {
      position: absolute;
      top: -7px;
      z-index: 2;
      height: 28px;
      width: 28px;
      text-align: left;
      margin-left: -11px;
      cursor: pointer;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
      background-color: #FFF;
      border-radius: 50%;
      outline: none;
    }
  }

  > input[type=range] {
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 3;
    height: 14px;
    top: -2px;
    width: 100%;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;

    &::-ms-track {
      -webkit-appearance: none;
      background: transparent;
      color: transparent;
    }

    &::-moz-range-track {
      -moz-appearance: none;
      background: transparent;
      color: transparent;
    }

    &:focus::-webkit-slider-runnable-track {
      background: transparent;
      border: transparent;
    }

    &:focus {
      outline: none;
    }

    &::-ms-thumb {
      pointer-events: all;
      width: 28px;
      height: 28px;
      border-radius: 0px;
      border: 0 none;
      background: red;
    }

    &::-moz-range-thumb {
      pointer-events: all;
      width: 28px;
      height: 28px;
      border-radius: 0px;
      border: 0 none;
      background: red;
    }

    &::-webkit-slider-thumb {
      pointer-events: all;
      width: 28px;
      height: 28px;
      border-radius: 0px;
      border: 0 none;
      background: red;
      -webkit-appearance: none;
    }

    &::-ms-fill-lower {
      background: transparent;
      border: 0 none;
    }

    &::-ms-fill-upper {
      background: transparent;
      border: 0 none;
    }

    &::-ms-tooltip {
      display: none;
    }
  }

  &__inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2.35rem;

    span {
      position: relative;

      &:before {
        content: "\20AC";
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
      }

      input {
        padding: 0.75rem;
        border: 1px solid #e5e7eb;
        border-radius: 0.375rem;
        width: 6rem;
        text-align: center;
        margin: 0;
      }
    }
  }
}


//.range-slider {
//  position: relative;
//  max-width: 36rem;
//  width: 100%;
//  padding: 1rem 0;
//
//  input[type=range] {
//    &::-webkit-slider-thumb {
//      pointer-events: all;
//      width: 24px;
//      height: 24px;
//      -webkit-appearance: none;
//    }
//
//    position: absolute;
//    pointer-events: none;
//    appearance: none;
//    z-index: 20;
//    height: 2px;
//    width: 100%;
//    opacity: 0;
//    cursor: pointer;
//  }
//
//  &__track {
//    position: relative;
//    z-index: 10;
//    height: 2px;
//
//    .track-background {
//      position: absolute;
//      z-index: 10;
//      inset: 0;
//      background-color: #e5e7eb;
//    }
//
//    .track-foreground {
//      position: absolute;
//      z-index: 20;
//      top: 0;
//      bottom: 0;
//      border-radius: 0.375rem;
//      background-color: $secondary;
//    }
//
//    .track-handle {
//      position: absolute;
//      z-index: 30;
//      width: 20px;
//      height: 20px;
//      top: 2px;
//      background-color: $secondary;
//      border-radius: 9999px;
//      transform: translateY(-50%);
//    }
//  }
//
//  &__inputs {
//    display: flex;
//    justify-content: space-between;
//    align-items: center;
//    padding-top: 1.25rem;
//
//    span {
//      position: relative;
//
//      &:before {
//        content: "\20AC";
//        position: absolute;
//        top: 50%;
//        left: 8px;
//        transform: translateY(-50%);
//      }
//
//      input {
//        padding: 0.75rem;
//        border: 1px solid #e5e7eb;
//        border-radius: 0.375rem;
//        width: 6rem;
//        text-align: center;
//        margin: 0;
//      }
//    }
//  }
//}