@use '../base/var' as *;

.page-appliance-shop {
  .page-header {
    display: flex;
    justify-content: space-between;
  }

  .appliance-shop-overview {
    display: flex;
    gap: 2rem;

    &__filter {
      transition: all 300ms ease;
      width: 300px;
      height: 100dvh;
      height: 100vh;
      overflow-y: auto;
      flex-shrink: 0;
      transform: translateX(-100%);

      position: fixed;
      top: 0;

      &.active {
        transform: translateY(0);
        box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.5);
        z-index: 9999;
      }

      @media only screen and (min-width: $breakpoint-lg) {
        position: initial;
        transform: translateY(0);
        box-shadow: none !important;
        transition: none;
      }
    }

    &__products {
      width: 100%;
      padding: 20px 20px 0;
    }
  }
}