dialog {
  position: relative;
  border: none;
  border: 1px solid #aaa;
  padding: 1rem 3rem;
  #custom-dialog-close{
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}