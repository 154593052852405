@use '../base/var' as *;

.main-menu {
  background-color: #fcfcfc;
  width: 240px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-shadow: 1px 0 3px rgba(0, 0, 0, 0.1);
  position: fixed;
  transform: translateX(-100%);
  max-height: 0;
  transition: all 300ms ease;

  @media only screen and (min-width: 1024px) {
    position: initial;
    transform: translateX(0);
    max-height: none;
  }

  &.collapsed {
    width: 30px;

    .main-menu__bottom-section, .main-menu__menu, .main-menu__top-section {
      display: none;
    }

    .main-menu__collapse svg {
      transform: rotate(90deg);
    }
  }

  &.active {
    transform: translateX(0);
    max-height: none;
    top: 50px;
  }

  nav {
    ul {
      li {
        border-radius: 5px;
        padding: 5px 12px;

        &:hover, &.active {
          .main-submenu {
            display: block;
            max-height: 400px;
          }

          .chevron-button svg {
            transform: rotate(-180deg);
          }
        }

        &:hover {
          background-color: $gray-light;
          color: $gray-dark;

          svg {
            fill: $gray-dark;
          }
        }

        .main-submenu {
          margin-top: 10px;
          transition: all 300ms ease;
          max-height: 0;
          overflow: hidden;

          a:hover {
            color: $gray-dark;
          }
        }

        &.active:not(.submenu-wrapper) {
          background-color: #DEE2E6;

          a {
            color: $secondary;

            svg {
              fill: $secondary;
            }
          }
        }

        a {
          display: flex;
          align-items: center;
          gap: 10px;
          color: $gray;

          svg {
            display: block;
            width: 20px;
            height: 20px;
            fill: $gray;
          }

          .chevron-button {
            margin-left: auto;
            margin-right: 0;
            padding: 0;
            height: 17px;

            svg {
              transition: all 200ms ease;
              transform: rotate(-90deg);
              height: 16px;
              width: 16px;
            }
          }
        }
      }
    }
  }

  &__collapse {
    position: absolute;
    top: 10px;
    right: 0;
    transform: translateX(50%);
    display: none;

    @media only screen and (min-width: 1024px) {
      display: block;
    }

    button {
      background-color: #fff;
      border-radius: 18px;
      height: 25px;
      line-height: unset;
      margin: 0;
      padding: 0;
      width: 25px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);

      &:hover {
        background-color: $primary;

        svg {
          fill: white;
        }
      }

      svg {
        transition: all 300ms ease;
        fill: $gray;
        transform: rotate(-90deg);
        width: 12px;
        height: 12px;
      }
    }
  }

  &__toggle {
    display: none;
  }

  &__top-section {
    padding: 20px 0;
    border: 1px solid $gray-light;
    border-style: none none solid none;

    .logo {
      display: flex;
      justify-content: center;

      img {
        width: 140px;
      }
    }
  }

  &__menu {
    padding: 20px 20px 0 20px;
    flex-grow: 1;
    overflow-y: auto;
    scrollbar-gutter: stable;
  }

  &__bottom-section {
    padding: 20px;
    border: 1px solid $gray-light;
    border-style: solid none none;

    .version {
      color: $gray;
      font-size: .8rem;
      padding-top: 1rem;
    }
  }
}