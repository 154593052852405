@use '../base/var' as *;

.appliance-inspections {
  &__dialog {
    .appliance-inspections-dialog {
      overflow: visible;

      &__filters {
        display: flex;
        gap: 1rem;
      }

      #custom-dialog-close {
        right: 0rem;
        top: 0rem;

        button {
          margin: 0;

          .close-button {
            height: 15px;
            width: 15px;
          }
        }
      }

      &__progress-bar {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        progress {
          width: 100%;
          -webkit-appearance: none;
          appearance: none;

          &::-webkit-progress-bar {
            border-radius: 10px !important;
          }

          &::-webkit-progress-value {
            background-color: $primary !important;
            border-radius: 10px !important;
          }
        }
      }


      .data-form button {
        width: 100%;
        text-align: center;
        margin-top: 1rem;
      }
    }
  }

  &__filters {
    .row {
      display: flex;
      gap: 1rem;
    }

    > div:not(:last-of-type) {
      margin-right: 1rem;
    }

    input, select {
      width: 200px;
      margin: 0;
    }

    button, .loading-spinner {
      align-self: flex-end;
    }
  }

  &__results {
    margin: 2rem 0;
    padding: .5rem 0;
    overflow: auto;

    input {
      margin: 0;
    }

    table {
      thead {
        th {
          cursor: pointer;
          white-space: nowrap;
          position: relative;

          &:hover {
            background-color: $primary;
            color: white;
          }

          &:not(:first-child):after {
            background: url('~../../_img/icons/arrow.svg') no-repeat center;
            background-size: auto;
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            visibility: hidden;
          }

          &.sorted {
            &--asc:after {
              transform: rotate(180deg);
            }

            &:after {
              visibility: visible;
            }
          }

          svg {
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
}