@use '../base/var' as *;

.spinning-loader {
  --spinner-thickness: 6px;
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, $primary);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - var(--spinner-thickness)), #000 0);
  animation: s3 1.6s infinite linear;

  &--secondary {
    background: conic-gradient(#0000 10%, $secondary);
  }

  &--small {
    --spinner-thickness: 5px;
    width: 25px;
    height: 25px;
  }

  &--large {
    --spinner-thickness: 8px;
    width: 60px;
    height: 60px;
  }

  &--slow {
    animation: s3 2s infinite linear;
  }

  &--fast {
    animation: s3 1s infinite linear;
  }
}

@keyframes s3 {
  to {
    transform: rotate(1turn)
  }
}