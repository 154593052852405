@use '../base/var' as *;

.alpineTable {
  .alpineTable-wrapper {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    position: relative;
  }

  &__filter {
    transition: all 300ms ease;
    background: #fff;
    height: 100%;
    top: 50px;
    left: 0;
    z-index: 1;

    opacity: 1;
    transform: translateX(0);
    position: unset;
    box-shadow: none;


    &.active {
      opacity: 1;
      transform: translateX(0);
    }

    .alpineTableFilter {
      width: 275px;

      &__category {
        .title {
          button {
            height: 25px;
            width: 100%;
            line-height: unset;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
            font-weight: bold;
            color: $secondary;
            padding: 20px 10px;
            border: 1px solid $gray;
            border-style: none none solid none;
            background-color: $gray-light;
            border-radius: 0;

            svg {
              fill: $secondary;
              width: 10px;
              height: 10px;
              transform: rotate(-90deg);
              transition: all 300ms ease;

              &.open {
                transform: rotate(-180deg);
              }
            }
          }
        }

        .options {
          li {
            padding-left: 20px;
            color: $gray;

            label {
              input {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  &__table {
    width: 100%;

    .search-filter {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      input {
        width: auto;
        flex-grow: 1;
      }

      button {
        margin: 0;
        height: 39px;
        @media only screen and (min-width: 1024px) {
          display: none;
        }
      }
    }

    tr.isSubmitted {
      background-color: lightgrey !important;
    }
  }
}