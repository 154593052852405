.page-delivery-notes {
  main {
    padding-bottom: 2rem;
  }

  table {
    width: 100%;
  }

  .pagination-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;

    ul {
      li {
        a {
          padding: 1rem;
        }
      }
    }
  }
  .alpineTable__table{
    table{
      tbody{
        td:first-child{
          white-space: nowrap;
        }
      }
    }
  }
}