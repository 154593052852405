.sub-menu {
  ul {
    li {
      display: inline-block;
      list-style-type: none;
      padding: 3.9px 10px 3.9px 10px;
      margin-left: -2px;
      margin-right: -2px;
      border-bottom: 1px solid #f2f2f2;

      &:hover {
        border-bottom: 1px solid #000;
      }

      &.active {
        border-bottom: 1px solid #000;
        background-color: #fff;
        color: #000;

        a, button {
          color: #2dbdcc;
        }
      }

      a, button {
        color: #989898;
        margin: 0;
      }
    }
  }
}