@use '../base/var' as *;

.appliance-shop-filter {
  background-color: white;
  width: 100%;
  flex-shrink: 0;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, .16);
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  &__title {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .hide-filter {
      margin: 0;
      padding: 0;

      i {
        font-size: 1.6rem;
        color: $secondary;
      }

      @media only screen and (min-width: $breakpoint-lg) {
        display: none;
      }
    }
  }

  &__filters {
    fieldset {
      legend {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: $secondary;
        font-weight: bold;
        padding: 1rem 1rem;
        border: 2px solid #E6E6E6;
        border-style: none none solid none;
        cursor: pointer;
        text-transform: capitalize;

        i {
          transition: transform 300ms ease;
          transform: rotate(90deg);

          &.expanded {
            transform: rotate(0);
          }
        }
      }

      .filter-values {
        padding: 0 1rem;
        margin-top: .5rem;
      }
    }
  }

  &__need-help {
    background-color: $secondary;
    color: white;
    flex-grow: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    a {
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }

    .title {
      font-weight: bold;
    }

    .employee-photo {
      border-radius: 50%;
      margin-right: .5rem;
      width: 50px;
      height: 50px;
    }
  }
}

#show-appliance-filter-button {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: $secondary;
  color: white;

  i {
    margin-right: .5rem;
  }

  @media only screen and (min-width: $breakpoint-lg) {
    display: none;
  }
}

.price-input-container {
  position: relative;

  &::before {
    content: '€';
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  input[type="number"] {
    padding-left: 20px;
  }
}