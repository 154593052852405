@use '../../base/var' as *;

.page-appliance-shop-quotes {

  form {
    display: flex;
    align-items: self-end;
    gap: 1rem;
    margin-bottom: 2rem;

    .search-fields {
      flex: 1;
      display: flex;
      gap: 1rem;

      .form-control {
        margin-bottom: 0;
        flex: 1;
      }
    }

    button {
      margin: 0;
    }
  }

  .quote-status {
    color: white;
    padding: 1px 10px;
    border-radius: 2px;
    text-align: center;

    &--approved {
      background: $alert-success;
    }

    &--declined {
      background: $alert-error;
    }
  }
}