@use '../../base/var' as *;

.page-request {
  .request-data {
    table {
      td {
        padding-right: 10px;

        &:first-child {
          color: #989898;
        }
      }
    }
  }

  .request-history-timeline {
    max-width: 550px;

    li {
      position: relative;
      margin-bottom: 1rem;
      padding-left: 1rem;

      &:before {
        content: '';
        height: calc(100% - 10px);
        border: 1px solid gray;
        border-style: none none none solid;
        position: absolute;
        left: 0;
        top: 10px;
      }

      &:after {
        border-radius: 6px;
        content: "";
        height: 6px;
        position: absolute;
        width: 6px;
        top: 7px;
        left: -2px;
        background-color: $gray;
      }
    }

    &__head {
      font-size: 0.9rem;
      color: $gray;
    }

    &__body {
      font-size: 1rem;
    }
  }
}