@import '../base/_var';

.main-header {
  background-color: $light;
  display: flex;
  border-bottom: 1px solid #dee2e6!important;

  .container {
    display: flex;
    align-items: center;
  }

  .header-logo {
    height: 67px;
  }

  nav {
    height: 100%;
    display: flex;
    align-items: center;
    flex-grow: 1;

    > ul {
      height: 100%;
      display: flex;
      align-items: center;

      li {
        height: 100%;
        flex-grow: 1;
        margin-right: 1rem;
        display: flex;
        border-bottom: 1px solid #dee2e6;
        border-width: 4px;
        border-color: transparent;
        position: relative;

        &:hover .sub-menu {
          visibility: visible;
          opacity: 1;
        }

        &.current-page {
          border-color: $secondary;
          opacity: 1;

          a {
            opacity: 1;
            color: $secondary;
          }
        }

        a {
          display: flex;
          align-items: center;
          opacity: .7;
          color: $gray;
          transition: .15s ease-out;
          text-decoration: none;
          background-color: transparent;
          font-weight: 500;
          font-size: 1.25rem;

          &:hover {
            text-decoration: none;
            color: $secondary;
          }
        }
      }
    }

  }

  .menu-buttons {
    display: flex;
    align-items: center;
    height: 100%;

    li {
      height: 100%;
      display: flex;
      align-items: center;
      border-left: 1px solid #dee2e6 !important;
      border-right: 1px solid #dee2e6 !important;

      a {
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        color: $gray;

        &:hover {
          text-decoration: none;
          color: $secondary;
        }

        span {
          margin-left: 0.5rem;
        }
      }
    }
  }
}


//.sub-menu {
//  transition: .15s ease-out;
//  opacity: 0;
//  visibility: hidden;
//  position: absolute;
//  bottom: 0;
//  transform: translateY(100%);
//  background-color: $light;
//
//  li {
//    a {
//      padding: 0.5rem 1rem;
//    }
//  }
//}