@use '../../base/var' as *;

.page-mobility-wheelchair-inspection-form{

  .form-labels, .form-fields {
    display: flex;
    flex-direction: column;
  }
  .form-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: stretch;
  }

  .form-group-fields {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .form-label {
    margin-bottom: 15px;
  }

  .required-span {
    color: red;
    font-size: smaller;
  }

  hr {
    color: #ededed ;
  }

   select {
    min-height: 39px;
    background-color: #ffffff;
    border: 2px solid #c8c8c8;
    border-radius: 4px;
    margin-bottom: 24px;
    box-sizing: border-box;
    padding: 8px;
    font-size: 16px;
    color: #333;
     width: 100%;
  }

  select:hover {
    border-color:#2dbdcc;
  }

  .custom-select option {
    background-color: #ffffff;
    color: #333;
    padding: 8px;
  }

  .field-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    align-items: stretch;
  }

  .option-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin: 2rem;
  }

  input[type="checkbox"] {
    margin-right: 5px;
  }
}
