@use '../../base/var' as *;

.page-appliance-shop-detail {
  .page-header {
    display: flex;
    justify-content: space-between;
  }

  container-type: inline-size;

  .product {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 400px;
    min-height: calc(100vh - 66px);

    @container(max-width: 1100px) {
      grid-template-columns: 1fr 270px;
    }

    &__details {
      margin: 0 auto;
      max-width: 800px;
      padding-top: 2rem;

      > * {
        margin-bottom: 2rem;
      }

      p {
        color: $secondary;
      }

      .product-specs {
        table {
          width: 100%;
          border: 1px solid $gray-light;
          table-layout: fixed;

          td {
            vertical-align: top;
            padding: 10px;

            li {
              list-style: disc;
            }
          }

          tr:nth-child(even) {
            td {
              background-color: #F1F8F7;
            }
          }
        }
      }

      .related-products {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 2rem;

        &__product {
          display: block;
          padding: 1rem;
          box-shadow: 1px 1px 5px rgba(0, 0, 0, .16);
          color: $secondary;

          &:hover {
            color: $secondary;
          }


          .price {
            color: $secondary;
            font-size: 1.875rem;
          }
        }
      }
    }

    &__add-to-cart {
      padding-top: 2rem;
      background-color: $gray-light;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .product-price {
        color: $secondary;
        font-size: 1.875rem;
      }

      .add-to-cart {
        display: grid;
        grid-template-columns: 63px 1fr;
        gap: .5rem;
        align-items: flex-start;
      }

      .available-for-organizations {
        &__warning {
          color: $alert-error;
        }

        &__list li {
          list-style: disc;
          margin-left: 1rem;
        }
      }

      .extra-info {
        i {
          color: $alert-success;
        }
      }
    }

  }
}