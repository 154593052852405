@use '../base/var' as *;

.appliance-search {
  position: relative;

  input {
    margin: 0;
    box-sizing: border-box;
    margin-bottom: 1rem;
  }

  &__results {
    width: 100%;
    background: white;
    border: 2px solid #c8c8c8;
    border-top: 0;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    border-radius: 0 0 3px 3px;
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;

    .loader {
      display: flex;
      justify-content: center;
      padding: 1rem 0;
    }

    li {
      button {
        width: 100%;
        padding: 0 .4rem;
        text-align: left;
        border-radius: 0;
        height: auto;

        &:hover {
          background-color: $primary;
          color: white;
        }
      }
    }
  }
}