.admin-user-locations {
  &__title {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;

    h2 {
      margin: 0;
    }
  }

  &__locations {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    align-items: flex-start;

    details {
      summary {
        cursor: pointer;
        display: list-item;
        font-weight: bold;
        margin: -.5em -.5em 0;
        padding: .5em;
        list-style: revert;
        transition: all 200ms ease;

        &::marker {
          transition: all 200ms ease;
        }

        button {
          float: right;
          margin: 0;
          height: auto;

          svg {
            width: 17px;
            height: 17px;
          }
        }
      }

      ul {
        input {
          margin: 0;
        }
      }

      button {
        text-transform: none;
      }
    }
  }

  .add-institutions-dialog {
    background-color: transparent;
    border: none;
    padding: 2rem;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 999;
    inset: 0;
    box-sizing: border-box;
    margin: 0;

    &::backdrop {
      background-color: rgba(0, 0, 0, 0.5);
    }

    div.dialog-wrapper {
      padding: 2rem;
      height: 100%;
      background-color: white;
      overflow-y: scroll;
    }

    &__institutions {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: .5rem;
      align-items: flex-start;
    }

    &__top-bar {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      gap: 1rem;
      align-items: center;

      button {
        height: auto;
      }

      input {
        margin: 0;
      }
    }
  }
}

