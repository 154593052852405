@use '../../base/var' as *;

.page-appliance-shop-external-management {

  .external-product-management {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 2rem;
    align-items: flex-start;

    &__content {
      td:first-child {
        width: 50px;
      }

      .groups-table {
        width: 100%;

        td, th {
          padding: .3rem .5rem;
          text-align: left;
        }

        td.no-padding {
          padding: 0;
        }

        .toggle-row-button {
          &.expanded i {
            transform: rotate(90deg);
          }

          i {
            transition: all 200ms ease;
          }

          span {
            margin-left: 1rem;
          }
        }
      }

      .products-table {
        width: 100%;

        td {
          text-align: left;
        }
      }

      td.toggle-cell {
        width: 150px;
      }

      tr.group-row {
        background-color: $gray-medium;

        td:first-child, td:nth-child(2) {
          cursor: pointer;
        }
      }

      tr.product-row {
        background-color: $gray-light;
      }
    }

    &__aside {
      padding: 1rem;
      background-color: $gray-light;
      border: 1px solid hsl(0 0% 88% / 1);
      display: flex;
      flex-direction: column;

      button {
        margin: 0;
      }
    }
  }
}