@use '../base/var' as *;

.page-login {
  background-color: transparent;

  &:after {
    content: '';
    width: 50vw;
    height: 100%;
    background: url('~../../_img/login.jpg') no-repeat center;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 50%;
    z-index: -1;
  }

  .login-form {
    background-color: $orange;
    box-shadow: 2px 1px 7px rgba(0,0,0,0.1);
    @media only screen and (min-width: $breakpoint-sm) {
      max-width: 66.66667%;
    }

    form {
      padding: 1.5rem;

      h1 {
        font-weight: bold;
      }

      label {
        color: $secondary;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
      }
    }

    .no-account {
      background-color: $light;
      padding: 1.5rem;
    }

    .error {
      margin-bottom: 1rem;
      background-color: $alert-error;
      color: white;
      padding: .5rem;
      border-radius: 3px;
    }
  }
}