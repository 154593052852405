@use '../base/var' as *;
.news-items {
  section {
    margin-top: 2rem;
    background-color: $light;
    border: 1px solid $gray;
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 1rem;
    .news-section-title{
      display: flex;
      justify-content: space-between;
    }
  }
}