@use '../base/var' as *;

.login-layout {
  min-height: 100vh;
  min-height: 100dvh;

  display: grid;
  grid-template-rows: auto 1fr auto;

  header {
    padding-top: 2rem;
    color: $secondary;
    font-size: 1.3rem;


    .logo {
      width: 300px;
      margin-bottom: 1rem;
      @media only screen and (min-width: $breakpoint-md) {
        width: 400px;
      }
    }
  }

  main {
    margin-bottom: 2rem;
  }

  footer {
    background-color: $gray-light;
    margin: 0;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (min-width: $breakpoint-md) {
      flex-direction: row;
      justify-content: space-between;
    }

    .footer-navigation {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: .7rem;
      @media only screen and (min-width: $breakpoint-md) {
        flex-direction: row;
      }

      a:not(:last-of-type):after {
        content: '·';
        margin-left: 10px;
        color: $gray;
      }
    }

    .copyright {
      margin-top: .5rem;
      text-align: center;
    }
  }

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
  }

  .wrapper {
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (min-width: $breakpoint-lg) {
    .container {
      max-width: 1000px
    }
  }

  @media (min-width: $breakpoint-xl) {
    .container {
      max-width: 1200px
    }
  }
}