.tabs {
  *[x-ref="tabsMenu"] {
    li {
      border-bottom: 1px solid #f2f2f2;
      display: inline-block;
      list-style-type: none;
      margin-left: -2px;
      margin-right: -2px;
      padding: 3.9px 10px;

      &.active {
        background-color: #fff;
        border-bottom: 1px solid #000;

        button {
          color: #2dbdcc;
        }
      }

      &:hover {
        border-bottom: 1px solid #000;
      }

      button {
        margin: 0;
      }
    }
  }
}