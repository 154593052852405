@use '../../base/var' as *;

.page-application-request-mutation-form {

  .main-form {
    margin-left: 30px;
    width: 100%;
  }

  .main-form form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
  }

  .panel {
    width: 100%;
    min-width: 400px;
    padding: 20px;
    box-sizing: border-box;
  }

}
