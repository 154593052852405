@use '../../base/var' as *;

.page-appliance-shop-group {
  main {
    padding: 20px;
  }

  .group-body {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 2rem;
    align-items: flex-start;

    &__content {
      .group-fields {
        flex: 1;

        .group-fields-head {
          background: $secondary;
          color: white;
        }

        .group-fields {
          display: flex;
          gap: 1rem;
          padding: 1rem 0;

          &__li-name {
            width: calc(50% - 64px);
            padding-left: 3rem;
            position: relative;
          }

          &__li-type {
            width: 25%;
          }

          &__li-standard-value {
            width: 25%;
          }

          &__li-remove-row {
            width: 30px;

            button {
              padding: 0;
              margin: 0;
            }
          }
        }

        .group-fields-list {
          margin-bottom: 1rem;

          .group-fields {
            border: 1px solid lightgrey;

            &__li-name {
              &.expanded::before {
                rotate: 180deg;
              }

              &::before {
                content: '';
                background: url('~../../_img/icons/chevron.svg') no-repeat center;
                background-size: auto;
                width: 16px;
                height: 16px;
                display: block;
                position: absolute;
                top: 12px;
                left: 14px;
                translate: 0 -50%;
                rotate: 90deg;
                transition: all 400ms ease;
              }
            }
          }
        }

        .field-group {
          padding: 1rem;
          border: 1px solid lightgrey;
          border-left: 3px solid darkblue;
          border-top: 0;
        }

        .add-fieldgroup {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    &__aside {
      padding: 1rem;
      background-color: $gray-light;
      border: 1px solid hsl(0 0% 88% / 1);
      display: flex;
      flex-direction: column;

      button {
        margin: 0;
      }
    }
  }
}