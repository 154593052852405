@use '../../base' as *;

.page-admin-wheelchair-management {

  .controle-sectie {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  .zoek-controle-container,
  .resultaten-container {
    flex: 1;
    min-width: 300px;
  }

  .zoek-controle {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .zoek-controle input {
    width: 150px;
    padding: 5px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
  }

  .zoek-controle input::placeholder {
    text-align: center;
    line-height: normal;
  }


  .zoek-controle button {
    padding: 5px 10px;
  }

  .wheelchair-link {
    color: #fff;
    background-color: #37becc;
    border-radius: 5px;
    padding: 10px;
  }

  .pagination {
    margin: 20px 0;
  }

  .pagination a {
    padding: 8px 12px;
    margin: 0 5px;
    text-decoration: none;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .pagination a.active {
    background-color: #2dbdcc;
    color: white;
  }

  .pagination a:hover {
    background-color: #2dbdcc;
    color: white;
  }
  hr {
    color: #ededed ;
  }

  #mechanic-container {
    display: flex;
    flex-wrap: wrap;
    max-height: calc(3 * 100px + 2 * 10px);
    overflow-y: auto;
    gap: 10px;
  }

  .mechanic-group {
    flex-basis: calc(20% - 10px);
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 15px;
    box-sizing: border-box;
  }

  .mechanic-group i {
    margin-top: 10px;
    cursor: pointer;
  }

  #wheelchair-container {
    display: flex;
    flex-wrap: wrap;
    max-height: calc(3 * 100px + 2 * 10px);
    overflow-y: auto;
    gap: 10px;
    border: solid 1px #ccc;
    border-radius: 5px;
  }

  .wheelchair-group {
    flex-basis: calc(20% - 10px);
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 15px;
    box-sizing: border-box;
  }

  .wheelchair-group i {
    margin-top: 10px;
    cursor: pointer;
  }

  #mechanic-container {
    border: solid 1px #ccc;
    border-right: 5px;
  }

  #add-mechanic-container {
    max-width: 600px;
    margin: 20px 0;
  }

  .add-mechanic-group {
    display: flex;
    gap: 10px;
  }

  .add-mechanic-group input {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    max-width: 250px;
  }

  .add-mechanic-group button {
    border-radius: 5px;
    padding: 8px 15px;
    cursor: pointer;
  }

  .add-mechanic-group button:hover {
    background-color: #2dbdcc;
    padding: 0 10px;
    color: white;
  }

  #add-mechanic {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  #mechanic-container {
    border: solid 1px #ccc;
    border-right: 5px;
  }

  #add-wheelchair-container {
    max-width: 600px;
    margin: 20px 0;
  }

  .add-wheelchair-group {
    display: flex;
    gap: 10px;
  }

  .add-wheelchair-group input {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    max-width: 250px;
  }

  .add-wheelchair-group button {
    border-radius: 5px;
    padding: 8px 15px;
    cursor: pointer;
  }

  .add-wheelchair-group button:hover {
    background-color: #2dbdcc;
    padding: 0 10px;
    color: white;
  }

  #add-wheelchair {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}
