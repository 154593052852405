@use '../base/var' as *;

.appliance-request {
  padding: .5rem;
  overflow-y: scroll;

  &__results {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);

    .appliance {
      background-color: #f7f7f7;
      padding: 10px;

      &__image {
        aspect-ratio: 1/1;
        width: 200px;

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }

      table {
        td {
          vertical-align: top;
        }
      }
    }
  }

  &__add-appliance {
    border: 1px dashed gray;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    padding: 1rem 0;
  }

  &__selected-appliances, &__selected-appliances-without-registration {
    .appliance {
      border: 2px solid #6c757d;
      border-radius: 3px;
      display: flex;
      padding: 2rem 1rem;
      position: relative;
      margin-bottom: 2rem;

      &__registration-fields {
        display: flex;
        gap: 1rem;
        width: 100%;

        > div {
          width: 100%;

          input, select {
            width: 100%;
          }
        }
      }

      &__appliancegroup-registration-fields {
        width: 100%;
      }

      &__info {
        //border: 1px #011f6e;
        border-right-style: solid;
        margin-right: 1rem;
        max-width: 400px;
        padding-right: 1rem;

        .appliance-image {
          aspect-ratio: 1/1;
          max-width: 200px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;

          }
        }
      }

      &__options {
        width: 100%;
        display: flex;
        gap: 1rem;
        flex-direction: column;

        .appliance-options-section {
          padding: 1rem 0;
          border: 1px solid gray;
          border-style: none none solid none;

          .form-control {
            margin-bottom: 1rem;
          }
        }

        .location {
          display: grid;
          gap: 1rem;
          grid-template-columns: 300px 1fr;
          margin-bottom: 2rem;

          strong {
            font-size: 15px;
          }
        }

        .appliances-in-same-room {
          ul {
            display: grid;
            grid-auto-flow: row;
            grid-template-columns: repeat(3, 1fr);
            gap: 0.2rem 0;

            label {
              display: inline-flex;
              align-items: flex-start;

              input {
                margin: 4px 6px 0;
              }
            }
          }
        }

        .related-appliances {
          ul {
            display: grid;
            grid-auto-flow: row;
            grid-template-columns: repeat(4, 1fr);
          }

          input {
            margin: 0;
          }
        }

        .exchange-appliance {
          &__selected-appliances {
            display: flex;
            gap: 1rem;
            border: 1px solid $gray;
            border-radius: 3px;
            padding: .5rem;
            background-color: white;

            h4 {
              width: max(200px, 33%);
            }

            table {
              th {
                font-weight: bold;
                text-align: left;
              }

              th, td {
                padding-right: .4rem;

                &:nth-child(1) {
                  font-weight: bold;
                }
              }
            }

            button {
              align-self: center;
              margin-left: auto;
            }
          }
        }

        .defect-data {
          &__item {
            background-color: $gray-light;
            padding: 1.5rem;
            margin-bottom: 1rem;

            .title {
              display: flex;
              align-items: center;
              gap: 1rem;
              margin-bottom: 1rem;

              h3 {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      &__remove-appliance-button {
        position: absolute;
        right: 10px;
        top: 0;

        button {
          margin: 0;
          padding: 0;

          svg {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }
}