@use '../base/var' as *;

.page-appliance-request {

  main {
    min-height: 100vh;
  }

  .choose-organization {
    display: flex;
    justify-content: center;
  }

  #appliance-requests-tiles {
    padding-top: 3rem;
    display: grid;
    grid-template-columns: repeat(3, 300px);
    gap: 1rem;
    height: 100%;
    place-items: center;

    section {
      width: 100%;
      height: 100%;

      a {
        height: 100%;
        display: block;
        background-color: $primary;
        color: white;
        padding: 30px 35px;
        border-radius: 5px;
        transition: all 400ms ease;

        &[href='javascript:void(0)'] {
          background-color: $gray;
          pointer-events: none;
        }

        &:hover:not([href='javascript:void(0)']) {
          background-color: hsl(186, 59%, 41%);
          transform: scale(1.01);
        }

        svg {
          fill: white;
          width: 45px;
          height: 60px;
        }

        h1 {
          color: white;
          font-size: 1.313rem;
        }
      }
    }
  }

  //dialog {
  //  width: 95vw;
  //  height: 95dvh;
  //  height: 95vh;
  //  border-radius: $border-radius-sm;
  //}

  //dialog > div {
  //  display: flex;
  //  flex-direction: column;
  //  flex: 1 1 auto;
  //  height: 100%;
  //}

  #appliance-request-step-buttons {
    display: flex;
    justify-content: space-around;
    margin: 2rem 0;
  }

  .appliance-request-final-step {
    form {
      h3 {
        margin-bottom: 2rem;
      }

      max-width: 730px;
      margin: auto;
    }
  }

  .urgency-notice {
    p {
      color: red;
    }

    a {
      color: #2dbdcc;
    }
  }
}