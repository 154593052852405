@use '../../base' as *;

.page-admin-user {
  .header {
    background-color: #fff;
    border-bottom: 1px solid #f2f2f2;
    height: auto;
    padding: 0 20px;
    display: flex;
    gap: 1rem;
    align-items: flex-end;
    padding-top: 2rem;

    ul {
      li {
        display: inline-block;
        list-style-type: none;
        padding: 3.9px 10px 3.9px 10px;
        margin-left: -2px;
        margin-right: -2px;
        border-bottom: 1px solid #f2f2f2;

        &.active {
          background-color: #fff;
          color: #000;
          border-bottom: 1px solid #000;

          button {
            color: #2dbdcc;
          }
        }

        button {
          color: $gray;
          margin: 0;
        }
      }
    }
  }

  .user-form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;

    &__base-info {
      width: 100%;
    }

    &__rights, &__permissions {
      padding: 1rem;
      background-color: $gray-light;
      //border: 1px solid $gray;
      border-radius: 2px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      gap: .5rem;

      .title {
        display: flex;
        gap: .5rem;

        button {
          height: auto;
          padding: 0;
          margin: 0;
          color: $primary;
        }
      }

      input {
        margin: 0;
        width: auto;
      }
    }
  }
  #passwordReq {
    font-family: 'Larsseit Regular', sans-serif;
    padding: 10px;
    background:#fefefe;
    border-radius:5px;
    border: none;
    outline: 2px solid #c8c8c8;
  }
  #passwordReq h4 {
    margin:0 0 10px 0;
    padding:0;
    font-weight:normal;
  }
  #passwordReq ul {
    list-style-type: none;
    padding-left: 0;
  }
  #passwordReq ul li {
    margin-bottom: 5px;
    padding-left: 20px;
    position: relative;
  }

  #passwordReq ul li.validCheck::before {
    content: "\2713";
    position: absolute;
    left: 0;
    color: green;
    font-weight: bold;
  }

  #passwordReq ul li.invalidCheck::before {
    content: "\2717";
    position: absolute;
    left: 0;
    color: red;
    font-weight: bold;
  }
  .invalidCheck {
    padding-left:22px;
    line-height:24px;
    color:#ec3f41;
  }
  .validCheck {
    padding-left:22px;
    line-height:24px;
    color:#37becc;
  }
}