@use '../../base' as *;

.page-admin-generate-form {

  .form-group-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 1rem;
    border: solid 1px #c8c8c8;
    border-radius: 5px;
  }

  .form-group {
    margin: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .form-group label {
    margin-bottom: 0.5rem;
  }

  .field-container {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr;
  }


  .field-row {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }

  .field-row label {
    flex: 1;
  }

  .field-row input,
  .field-row textarea,
  .field-row select {
    flex: 2;
  }


  #fieldContainer {
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
  }

  .button--secondary,
  .button--primary {
    display: block;
    margin-top: 10px;
  }

  select {
    min-height: 39px;
    background-color: #ffffff;
    border: 2px solid #c8c8c8;
    border-radius: 4px;
    margin-bottom: 24px;
    box-sizing: border-box;
    padding: 8px;
    font-size: 16px;
    color: #333;
    width: 100%;
  }

  select:hover {
    border-color:#2dbdcc;
  }

  .option-label, .option-value {
    margin: 10px;
  }
  .delete-option-button {
    margin: 10px;
  }
  .add-option-button {
    margin: 10px;
  }
  .required-span {
    color: red;
    font-size: smaller;
    margin-left: 5px;
  }
   hr {
     width: 100%;
     color: #ededed ;
   }

  .options-container {
    border: 1px solid #ededed;
    border-radius: 5px;
    margin: 10px;
  }

  .option-delete-button {
    width: fit-content;
  }

  .button-group {
    margin: 1rem;
  }
}
